import '../Order/OrderSummary/index.css';

function OrderConfirmed() {

    return (
        <>
            <div className="form-container">
                <h3>Thank you for your order!</h3>
            </div>
        </>
    );
}

export default OrderConfirmed;