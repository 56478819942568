import './style.css';

function Farm() {

    return (
        <div id="farm" className="farm">
        </div>
    );
}

export default Farm;